import React from 'react';
import './Content.css';

function Content() {
  return (
    <div className="App-content">
      <section>
        <p>Hier gaat het gebeuren</p>
      </section>
    </div>
  );
}

export default Content;
